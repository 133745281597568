import './app.css';
import './styles.css';

function App() {
  return (
      <div className="mt-8 p-5">
        <h1 className="text-5xl underline font-se">Sleeping Elephant</h1>

        <h2 className="font-se underline text-4xl">Tabs</h2>

        <div className="mt-6">
          <h3 className="font-se underline text-3xl">File Tabs</h3>

          <div className="my-6 flex">
            <div className="border-b border-soft-black font-se text-xl">
              <button
                  className="border-x border-t rounded-t-md p-2 me-2 border-soft-black bg-soft-black text-soft-white"
              >
                <i className="fa-solid fa-shrimp fa-xs me-1"></i>
                Starters
              </button>
              <button
                  className="border-x border-t rounded-t-md p-2 me-2 border-soft-black hover:text-soft-white
                    hover:bg-soft-black"
              >
                <i className="fa-solid fa-burger fa-xs me-1"></i>
                Mains
              </button>
              <button
                  className="border-x border-t rounded-t-md p-2 me-2 border-soft-black hover:text-soft-white
                    hover:bg-soft-black"
              >
                <i className="fa-solid fa-cake-candles fa-xs me-1"></i>
                Desserts
              </button>
              <button
                  className="border-x border-t rounded-t-md p-2 me-2 border-soft-black hover:text-soft-white
                    hover:bg-soft-black"
              >
                <i className="fa-solid fa-mug-saucer fa-xs me-1"></i>
                Drinks
              </button>
            </div>
          </div>
        </div>
      </div>
  );
}

export default App;
